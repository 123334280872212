/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/montserrat-v12-latin/montserrat-v12-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../assets/fonts/montserrat-v12-latin/montserrat-v12-latin-regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/montserrat-v12-latin/montserrat-v12-latin-regular.woff2") format("woff2"), url("../assets/fonts/montserrat-v12-latin/montserrat-v12-latin-regular.woff") format("woff"), url("../assets/fonts/montserrat-v12-latin/montserrat-v12-latin-regular.ttf") format("truetype"), url("../assets/fonts/montserrat-v12-latin/montserrat-v12-latin-regular.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/montserrat-v12-latin/montserrat-v12-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../assets/fonts/montserrat-v12-latin/montserrat-v12-latin-700.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/montserrat-v12-latin/montserrat-v12-latin-700.woff2") format("woff2"), url("../assets/fonts/montserrat-v12-latin/montserrat-v12-latin-700.woff") format("woff"), url("../assets/fonts/montserrat-v12-latin/montserrat-v12-latin-700.ttf") format("truetype"), url("../assets/fonts/montserrat-v12-latin/montserrat-v12-latin-700.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

html {
  height: 100%; }

body {
  /*background-image: url(../img/start_images/discuss_app_screenshot.png), linear-gradient(#579cbd, #6dc5ee);*/
  background-image: radial-gradient(ellipse, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.9) 100%), radial-gradient(#c8d6f4 0%, #c8d6f4 100%);
  background-repeat: repeat;
  background-position: center;
  background-size: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px; }

.content {
  position: relative;
  margin: 20px;
  height: auto; }
  .content .sub-content {
    position: relative;
    max-width: 1110px;
    margin: auto; }
    .content .sub-content .single-screenshot {
      max-height: 100%; }

.sub-content {
  position: relative;
  max-width: 1110px;
  margin: auto; }

.box-image {
  width: 100%;
  border: 5px solid #222;
  border-radius: 5px;
  vertical-align: bottom;
  align-self: flex-end;
  margin-top: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.content-box p {
  flex-grow: 2; }

.content-box {
  padding: 20px;
  min-height: 100%;
  display: flex;
  max-width: 350px;
  /* width + padding + border of image */
  flex-grow: 1;
  flex-direction: column; }

.header {
  text-align: center;
  color: #fff;
  /*text-shadow: 0px 0px 4px rgba(0,0,0,0.5);*/
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 1; }

.logo {
  font-size: 20px;
  letter-spacing: 2px;
  display: inline-block;
  font-weight: bold; }
  .logo img {
    vertical-align: baseline;
    margin-right: 18px;
    margin-bottom: -1px; }

.sub-title {
  padding-left: 40px;
  font-size: 20px;
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.8px; }

.row.cols-same-height {
  display: flex; }

.row.cols-same-height > [class*='col-'] {
  display: flex;
  flex-direction: column; }

a {
  color: #2f90ca; }

a:hover, a:focus {
  color: #2b7aac;
  text-decoration: underline; }

.box-get-account {
  display: inline-block;
  vertical-align: top; }

.box-login {
  display: inline-block;
  vertical-align: top; }

.row.action {
  text-align: center;
  margin-bottom: 10px;
  padding: 15px; }

.row.action p {
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.8); }

.or-wrapper {
  position: relative;
  height: 70px;
  margin: 0;
  display: inline-block;
  width: 80px;
  vertical-align: top; }
  .or-wrapper .line-top {
    top: 0;
    bottom: auto;
    height: 35%; }
  .or-wrapper .line-bottom {
    top: auto;
    bottom: 0;
    height: 35%; }

.line {
  position: absolute;
  left: 49%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: rgba(255, 255, 255, 0.3);
  z-index: 1; }

.wordwrapper {
  text-align: center;
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -12px;
  z-index: 2; }

.word {
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 3px;
  font-size: 18px;
  display: inline;
  font-weight: normal; }

.btn {
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857;
  border-radius: 3px; }

.btn-primary {
  background-color: #abc2d0;
  color: #000;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1px;
  border-color: transparent;
  padding-left: 20px;
  padding-right: 20px; }
  .btn-primary.demo {
    background-color: transparent;
    border-color: #abc2d0;
    color: #abc2d0; }

.btn-primary:hover {
  color: #000;
  background-color: #9ab1bf;
  border-color: transparent; }

.btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle,
.btn-primary:focus, .btn-primary.focus,
.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .open > .btn-primary.dropdown-toggle:hover, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle.focus {
  color: #000;
  background-color: #9ab1bf;
  border-color: transparent; }

.form-control {
  height: auto;
  padding: 9px 12px;
  font-size: 14px; }

h2, .h2 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  color: #ffffff; }

.popover {
  font-family: "proxima-nova", sans-serif;
  font-size: 16px; }

.footer {
  margin-top: 30px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }

.footer-menu {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  margin-bottom: 10px; }
  .footer-menu a {
    color: rgba(255, 255, 255, 0.6); }

.section {
  text-align: center; }

.single-screenshot {
  max-height: 100%;
  padding-bottom: 160px;
  padding-top: 120px;
  max-width: 100%;
  padding-left: 5px;
  padding-right: 5px; }

.row.explanation {
  padding: 0 40px 30px 40px;
  text-align: left; }

.content-text {
  padding: 0 5px;
  flex-grow: 2;
  color: rgba(255, 255, 255, 0.8); }

a.imprint {
  margin-right: 20px;
  margin-left: 20px; }

.email-success {
  color: rgba(255, 255, 255, 0.7); }

.privacy-policy {
  font-size: 12px; }
  .privacy-policy h4 {
    margin-top: 20px;
    font-size: 12px;
    font-weight: bold; }

.modal.request-account .close {
  font-size: 30px; }

.modal.request-account .modal-body {
  padding: 40px;
  padding-top: 35px; }

.modal.request-account h1 {
  margin-top: 0;
  font-size: 30px;
  margin-bottom: 30px; }

.modal.request-account .form-inline {
  margin-top: 50px;
  text-align: center; }

.modal.request-account .email-success {
  color: #000000; }

.modal-content {
  border-radius: 0; }

@media (max-width: 480px) {
  .box-get-account {
    display: block; }
  .box-login {
    display: block; }
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; } }

@media (min-width: 480px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; } }

@media (max-width: 666px) {
  .main-title {
    display: block; }
  .sub-title {
    padding-left: 0;
    font-size: 18px; }
  .content {
    margin: 200px 0px 0px 0px; }
  .row.action {
    padding: 0; } }

.legal-notices-inpage {
  display: none; }

.inpage {
  display: none; }

.legal-notices-separate {
  display: block; }

.footer {
  display: block; }

@media (max-width: 992px) {
  .content-box {
    /*padding: 10px;*/ } }

@media (max-width: 767px) {
  .header {
    top: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: rgba(57, 61, 70, 0.7);
    padding: 10px 5px 4px 5px; }
  .row.cols-same-height {
    display: block;
    padding-top: 100px; }
  .row.cols-same-height > [class*='col-'] {
    display: block; }
  .content-box {
    /*padding: 20px 60px;*/
    margin: auto; }
  .box-get-account {
    display: block; }
  .box-login {
    display: block; }
  a.imprint {
    margin-left: 0; }
  .row.action {
    padding-bottom: 20px; }
  .or-wrapper {
    height: 60px;
    width: 80%;
    margin: 0; }
    .or-wrapper .line-top {
      left: 0;
      right: auto;
      top: 50%;
      bottom: auto;
      height: 1px;
      width: 35%; }
    .or-wrapper .line-bottom {
      left: auto;
      right: 0;
      top: 50%;
      bottom: auto;
      height: 1px;
      width: 35%; }
  .line {
    left: 0;
    right: 0;
    top: 32px;
    height: 1px;
    width: auto; }
  /*.row.action {*/
  /*margin-bottom: 20px;*/
  /*padding: 10px 30px;*/
  /*}*/
  .legal-notices-inpage {
    display: block; }
  .inpage {
    display: block; }
  .legal-notices-separate {
    display: none; }
  .footer {
    display: none; }
  .footer-menu {
    font-size: 12px; } }

@media (max-width: 480px) {
  .content-box {
    padding: 10px; } }

.carousel-control.left, .carousel-control.right {
  background-image: none; }
